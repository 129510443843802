import axios from "axios";


const formatNumber = (number) => {
    if (number.length !== 12) { 
        return "-" 
    }
    return number.slice(0,3) + " " + number.slice(3, 6) + "-" + number.slice(6, 9) + "-" + number.slice(9, 12)
}

const toPolishDate = (date) => {
    // date: Date()
    return date.toLocaleDateString('pl-PL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    })
}

const getUserFullName = (user_id, users) => {
    const user = users.find((u) => u.uuid === user_id)
    return user ? `${user.first_name} ${user.last_name}` : "Nie znaleziono"
}

const validatePassword = (password) => {
    if (!password) {
        return 'Podaj hasło';
      } else {
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
        if (password.length < 8) {
            return "Hasło musi miec min. 8 znaków długości.";
        } else if (!specialCharRegex.test(password)) {
            return "Hasło musi zawierać min. 1 znak specjanly.";
        } else if (!/\d/.test(password)) {
            return "Hasło musi zawierać min. 1 cyfrę";
        } else if (!/[A-Z]/.test(password)) {
            return "Hasło musi zawierać min. 1 wielką literę";
        }
    }
    return null
}

const getStatusVerboseName = (status) => {
    const visitStatusMapping = {
        CLI_CANC: "Odwołana przez klienta", 
        USR_CANC: "Odwołana przez salon", 
        BOOKED: "Umówiona",
        DEP_UNPD: "Zaliczka nieopłacona",
        DEP_EXPD: "Zaliczka wygasła",
        NOT_CONF: "Nie potwierdzona"
    }
    return(visitStatusMapping[status])
}

const salonIdToURLMap = {
    [process.env.REACT_APP_HAIRROOM_SALON_UUID]: "/hairroom",
    [process.env.REACT_APP_PRUSZKOWSKI_BARBER_UUID]: "/pruszkowski_barber",
    [process.env.REACT_APP_JAMESON_BARBER_UUID]: "/jameson",
    [process.env.REACT_APP_VENUS_UUID]: "/venus",
    [process.env.REACT_APP_FRYZJERSKIE_INSPIRACJE_UUID]: "/fryzjerskie_inspiracje",
    [process.env.REACT_APP_NAILS_G_UUID]: "/nails-g",
    [process.env.REACT_APP_NAIL_GALLERY_UUID]: "/nailGallery",
    [process.env.REACT_APP_STUDIO7_UUID]: "/studio7",
    [process.env.REACT_APP_ETNO_STUDIO_UUID]: "/etno_studio",
    [process.env.REACT_APP_BELLO_OCCHIO_UUID]: "/belloOcchio",
    [process.env.REACT_APP_ELIANA_UUID]: "/eliana",
    [process.env.REACT_APP_BEAUTY_BY_K_UUID]: "/beautyByK",
    [process.env.REACT_APP_DEFAULT_SALON_UUID]: "/demo",
}

const mapSalonIdToURL = (salon_id) => {    
    return salonIdToURLMap[salon_id] || "/"
}


const urlToSalonIdMap = {
    "/hairroom": process.env.REACT_APP_HAIRROOM_SALON_UUID,
    "/pruszkowski_barber": process.env.REACT_APP_PRUSZKOWSKI_BARBER_UUID,
    "/jameson": process.env.REACT_APP_JAMESON_BARBER_UUID,
    "/venus": process.env.REACT_APP_VENUS_UUID,
    "/fryzjerskie_inspiracje": process.env.REACT_APP_FRYZJERSKIE_INSPIRACJE_UUID,
    "/nails-g": process.env.REACT_APP_NAILS_G_UUID,
    "/nailGallery": process.env.REACT_APP_NAIL_GALLERY_UUID,
    "/studio7": process.env.REACT_APP_STUDIO7_UUID,
    "/etno_studio": process.env.REACT_APP_ETNO_STUDIO_UUID,
    "/belloOcchio": process.env.REACT_APP_BELLO_OCCHIO_UUID,
    "/eliana": process.env.REACT_APP_ELIANA_UUID,
    "/beautyByK": process.env.REACT_APP_BEAUTY_BY_K_UUID,
    "/demo": process.env.REACT_APP_DEFAULT_SALON_UUID,
}

const mapURLToSalonId = (url) => {
    return urlToSalonIdMap[url] || null
}

function getCookieValue(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function isEndBeforeStart(start_hour, end_hour) {
    // Split the time strings into hours and minutes
    let [startHour, startMinute] = start_hour.split(":").map(Number);
    let [endHour, endMinute] = end_hour.split(":").map(Number);

    // Convert hours and minutes to total minutes since midnight
    let startTotalMinutes = startHour * 60 + startMinute;
    let endTotalMinutes = endHour * 60 + endMinute;

    // Compare the total minutes
    return endTotalMinutes < startTotalMinutes;
}

const getVisitDate = (visit) => {
    let minStartDate = null;

    for (const timeslot of visit.timeslots) {
        const startDate = new Date(timeslot.start_date);

        if (!minStartDate || startDate < minStartDate) {
            minStartDate = startDate;
        }
    }

    return minStartDate
}

const fetchClients = async (baseUrl, paginator, setPaginator, setError, setClients, authHeader, newLimit=150) => {
    try {
        let response = await axios.get(`${baseUrl}/clients/?limit=${paginator.limit}&offset=${paginator.offset}`, {
            headers: {'Authorization': `${authHeader()}`,'Content-Type': 'application/json'},
        });

        let results = response.data.results
        while (response.data.next) {
            response = await axios.get(response.data.next, {
                headers: { 'Authorization': `${authHeader()}`, 'Content-Type': 'application/json', },
            });
            results = [...results, ...response.data.results]
            setPaginator({
                ...paginator,
                offset: paginator.offset+paginator.limit, 
                next: response.data.next,
                loaded: response.data.next ? paginator.offset + paginator.limit : paginator.offset + response.data.results.length
            })
        }
        setClients(prevClientsData => [...prevClientsData, ...results]);
    } catch (error) { 
        console.log(error)
        setError('Wystąpił błąd podczas pobierania klientów');
    }
};

export { formatNumber, toPolishDate, mapSalonIdToURL, getCookieValue, fetchClients, getUserFullName,
    formatDate, mapURLToSalonId, isEndBeforeStart, getVisitDate, getStatusVerboseName, validatePassword }