import { useState, useEffect } from 'react';

function getStorageValue(key, defaultValue) {
  try {
    // Getting stored value
    const saved = localStorage.getItem(key);
    const initial = JSON.parse(saved);
    return initial !== null && initial !== undefined ? initial : defaultValue;
    // return initial || defaultValue;
  } catch (error) {
    // Handle JSON parsing errors, if any
    console.error(`Custom error in useLocalStorage: Error parsing JSON for key '${key}':`, error);
    return defaultValue;
  }
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => getStorageValue(key, defaultValue));

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);


  return [value, setValue];
};