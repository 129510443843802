import axios from 'axios'
import { createRefresh } from 'react-auth-kit'
import { JWT_LIFETIME, JWT_REFRESH_LIFETIME } from './constants'


const removeAuthCookies = () => {
    const cookies = document.cookie.split(";");

    cookies.forEach(cookie => {
        const cookieName = cookie.split("=")[0].trim();

        // Check if the cookie name starts with '_auth'
        if (cookieName.startsWith("_auth")) {
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
        }
    });
    window.location.reload()
};


const refreshApi = createRefresh({
    interval: 50, // 50 instead of 60 because it's often delayed
    refreshApiCallback: async (
        { 
            authToken,
            authTokenExpireAt,
            refreshToken,
            refreshTokenExpiresAt,
            authUserState
        }) => {
        console.log("Running refresh...")
        try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/jwt/refresh/`, {'refresh': refreshToken})
        console.log("Success!")
        return {
            isSuccess: true,
            newAuthToken: response.data.access,
            newAuthTokenExpireIn: JWT_LIFETIME,
            newRefreshTokenExpiresIn: JWT_REFRESH_LIFETIME
        }
        }
        catch(error) {
            console.log("Fail!")
            console.error(error)
            removeAuthCookies() // Manually log out because react-auth-kit often fails to do so
            return {
                isSuccess: false
            } 
        }    
  }
})

export default refreshApi