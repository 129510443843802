import React from "react";
import ReactDOM from "react-dom/client";
// import ReactDOM from "react-dom/client";
import App from "./App";
import refreshApi from "./Functions/refreshTokenHandler";
import posthog from "posthog-js";
import { PostHogProvider} from 'posthog-js/react'

// Libraries
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { LazyMotion, domMax } from "framer-motion";
import { AuthProvider } from 'react-auth-kit'

// css
import "./Assets/css/icons.css"
import "./Assets/css/global.css"
import "./Assets/css/pages.css"
import "./index.scss"

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import * as Sentry from "@sentry/react";

if (!window.location.host.includes('192.168.1.26') && !window.location.host.includes('localhost')) {
  Sentry.init({
    dsn: "https://f9df2b5e8203a4833873e96052840495@o4505850485997568.ingest.sentry.io/4506689244233728",
    integrations: [],
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

const Fallback = () => {
  return(
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div>
        <h3>Wystąpił błąd</h3>

        <p className="text-center">
          <span onClick={() => window.location.reload(true)} className="fw-bold" style={{borderBottom: "1px solid black", cursor: "pointer"}}>Kliknij</span>
          , aby spróbować ponownie
        </p>
      </div>
    </div>
  )
}

root.render(
  <Sentry.ErrorBoundary fallback={Fallback}>

  <LazyMotion features={domMax}>
    <AuthProvider 
      authType = {'cookie'} 
      authName={'_auth'} 
      refresh={refreshApi} 
      cookieDomain={window.location.hostname} 
      cookieSecure={window.location.protocol === "https:"}
      >
      
      <ParallaxProvider>  
        <BrowserRouter>
          <PostHogProvider client={posthog}>
            <App />
          </PostHogProvider>
        </BrowserRouter>
      </ParallaxProvider>

    </AuthProvider>
  </LazyMotion>
  </Sentry.ErrorBoundary>
);