function CookiesNotice({ acceptCookies }) {
    return (
      <section className="fixed max-w-md p-4 mx-auto bg-white border border-gray-200 left-12 bottom-16 rounded-2xl" style={{zIndex: "9999"}}>
        <h6 className="font-semibold text-gray-800">
          🍪 Używamy plików cookie
        </h6>
        <p className="mt-4 text-sm text-gray-600 ">
          Ta strona korzysta z plików cookie - zarówno własnych, jak i zewnętrznych dostawców. 
          Dzięki temu możemy dostarczać podstawowe usługi i analizować ruch na stronie. Korzystając z 
          naszej strony, wyrażasz zgodę na wykorzystywanie plików cookie. Zgodę możesz wycofać na dole polityki prywatności. 
          <a href="privacy" className="text-blue-500 hover:underline">
            Więcej w polityce prywatności.
          </a>
        </p>
  
        <div className="flex items-center justify-between mt-4 gap-x-4 shrink-0">
          <button
            onClick={() => {acceptCookies(true)}}
            type="button"
            className="text-xs bg-gray-900 font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
          >
            Akceptuj
          </button>
        </div>
      </section>
    );
  }

export default CookiesNotice